import { useCallback, useState, useEffect } from 'react';
import styles from './Main.module.css';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import Upload from '../images/upload.svg';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Main = () => {
  const navigate = useNavigate();

  const [fileInfo, setFileInfo] = useState(); // 단일 파일

  const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {},
  });

  // 엑셀파일 업로드와 API 조회 후 결과 값 다운로드 하는 메서드
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    console.log('FILE:', file);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await client.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('파일 업로드 성공!');
      console.log(response.data);
    } catch (error) {
      alert('파일 업로드 실패.');
      console.error('Error:', error);
    }
    // const strFileInfo = `${file.path} (${(file.size / 1024).toFixed(2)} KB)`;
    // setFileInfo(strFileInfo);

    // const reader = new FileReader();
    // reader.onload = async (event) => {
    //   // const binaryStr = event.target.result;
    //   // const workbook = XLSX.read(binaryStr, { type: 'binary' });
    //   // const sheetName = workbook.SheetNames[0];
    //   // const sheet = workbook.Sheets[sheetName];
    //   // const data = XLSX.utils.sheet_to_json(sheet, {header: 1, defval: ''});

    //   // setFileInfo([]);

    //   // 여기서 엑셀 파일 업로드 및 결과 확인
    // };
    // reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ['.xlsx'],
    }, // MIME TYPE : [extensions] ex)  'image/*': ['.jpeg', '.png'] 'text/html': ['.html', '.htm'],
  });


  return (
    <div className={styles['container']}>
      <div className={styles['wrap']}>
        <div className={styles['title']}>권한관리시스템 인사정보 엑셀파일 업로드</div>
        <div className={styles['attachment-wrap']}>
          <div className={styles['item-wrap']}>
            <div className={styles['item-info']}>
              <p>1. 플렉스에서 다운로드 받은 파일 그대로 집어넣습니다.</p>
            </div>
          </div>
          <div
            className={styles['attachment']}
            {...getRootProps()}
          >
            <div className={styles['img']}><img src={Upload} alt='upload' /></div>
            <p>첨부파일을 드래그 앤 드롭하거나 클릭하여 업로드 하세요.</p>
            <p>1개의 파일만 업로드 가능합니다.</p>
          </div>
          <div className={styles['file-name-wrap']}>
            <input {...getInputProps()} />
            {fileInfo}
            {/* {files}
            {files2} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main;